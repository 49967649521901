import Vue from 'vue'
import VueRouter from 'vue-router'
// import MainLayout from '@/layouts/Main'
import store from '@/store'
import { verifyToken } from '@/helpers/token.js'

// import Login from '@/views/Auth/Login'
// import ForgotPassword from '@/views/Auth/ForgotPassword'
// import Dashboard from '@/views/Teacher/Dashboard'
// import AccountTeacher from '@/views/Teacher/Account'
// import AccountStudent from '@/views/Student/Account'
// import DashboardStudents from '@/views/Student/DashboardStudents'
// Start Admin Routes
// import AccountAdmin from '@/views/Admin/Account'
// import DashboardAdmin from '@/views/Admin/Dashboard'
// import TeachersAdmin from '@/views/Admin/Teachers'
// import AddTeachersAdmin from '@/views/Admin/Teachers/Add'
// import DetailTeacherAdmin from '@/views/Admin/Teachers/Detail'
// import ScheduleTeachersAdmin from '@/views/Admin/Teachers/Schedule'
// import StudentsAdmin from '@/views/Admin/Students'
// import DetailStudentAdmin from '@/views/Admin/Students/Detail'
// import AddStudentAdmin from '@/views/Admin/Students/Add'
// import ClassesAdmin from '@/views/Admin/Classes'
// import DetailClassAdmin from '@/views/Admin/Classes/Detail'
// End Admin Routes
// import DetailSchedule from '@/views/Teacher/DetailSchedule'
// import Planning from '@/views/Teacher/Planning'
// import Schedule from '@/views/Teacher/Schedule'
// import Assignments from '@/views/Teacher/Assignments'
// import GradingAssignments from '@/views/Teacher/Assignments/Grading'
// import PresencesAssignments from '@/views/Teacher/Assignments/Presences'
// import EditSchedule from '@/views/EditSchedule'
// import Reports from '@/views/Teacher/Reports'
// import DetailReport from '@/views/Teacher/Reports/Detail'
// import CbtTeacher from '@/views/Teacher/Cbt'
// import AddCbtTeacher from '@/views/Teacher/Cbt/Add'
// import DetailCbtTeacher from '@/views/Teacher/Cbt/Detail'
// import ResultCbtTeacher from '@/views/Teacher/Cbt/Result'
// import HomeroomTeacher from '@/views/Teacher/Homeroom'
// import DetailHomeroomTeacher from '@/views/Teacher/Homeroom/Detail'
// import CbtStudent from '@/views/Student/CbtStudent'
// import ClassStudent from '@/views/Student/ClassStudent'
// import ScheduleStudent from '@/views/Student/ScheduleStudent'
// import DetailScheduleStudent from '@/views/Student/DetailSchedule'
// import DetailSubjectStudent from '@/views/Student/DetailSubject'
// import ExamCBTList from '@/views/Student/ExamCBTList'
// import StartExamCBT from '@/views/Student/ExamCBTList/StartExamCBT'

// TRY NEW IMPORT FOR IMPROVING PERFORMANCE

const mainLayout = () =>
  import(/* webpackChunkName: "mainLayout" */ '../layouts/Main')

const login = () =>
  import(/* webpackChunkName: "login" */ '../views/Auth/Login/New')
// const login = () =>
//   import(/* webpackChunkName: "login" */ '../views/Auth/Login')
const forgotPassword = () =>
  import(
    /* webpackChunkName: "forgotPassword" */ '../views/Auth/ForgotPassword'
  )
const publicReportPreview = () =>
  import(
    /* webpackChunkName: "publicReportPreview" */ '../views/Public/ReportPreview'
  )

const publicGraduationLetterValidation = () =>
  import(
    /* webpackChunkName: "publicGraduationLetterValidation" */ '../views/Public/GraduationLetterValidation'
  )

// TEACHER
const accountTeacher = () =>
  import(/* webpackChunkName: "accountTeacher" */ '../views/Teacher/Account')
const dashboardTeacher = () =>
  import(
    /* webpackChunkName: "dashboardTeacher" */ '../views/Teacher/Dashboard'
  )

const scheduleTeacher = () =>
  import(/* webpackChunkName: "scheduleTeacher" */ '../views/Teacher/Schedule')
const detailScheduleTeacher = () =>
  import(
    /* webpackChunkName: "scheduleTeacher" */ '../views/Teacher/DetailSchedule'
  )
const editScheduleTeacher = () =>
  import(/* webpackChunkName: "scheduleTeacher" */ '../views/EditSchedule')

// const planningTeacher = () =>
//   import(/* webpackChunkName: "planningTeacher" */ '../views/Teacher/Planning')

const planningTeacherNew = () =>
  import(
    /* webpackChunkName: "planningTeacher" */ '../views/Teacher/Planning/New'
  )

const assignmentTeacher = () =>
  import(
    /* webpackChunkName: "assignmentTeacher" */ '../views/Teacher/Assignments'
  )
const gradingAssignmentTeacher = () =>
  import(
    /* webpackChunkName: "gradingAssignmentTeacher" */ '../views/Teacher/Assignments/Grading/New'
  )
const presencesAssignmentTeacher = () =>
  import(
    /* webpackChunkName: "presencesAssignmentTeacher" */ '../views/Teacher/Assignments/Presences'
  )

const reportsTeacher = () =>
  import(/* webpackChunkName: "reportsTeacher" */ '../views/Teacher/Reports')
const detailReportsTeacher = () =>
  import(
    /* webpackChunkName: "detailReportsTeacher" */ '../views/Teacher/Reports/ClassReport'
  )

const principalTeacher = () =>
  import(
    /* webpackChunkName: "curriculumTeacher" */ '../views/Teacher/Principal'
  )
const curriculumTeacher = () =>
  import(
    /* webpackChunkName: "curriculumTeacher" */ '../views/Teacher/Curriculum'
  )
const HomeroomTeacherProgress = () =>
  import(
    /* webpackChunkName: "curriculumTeacher" */ '../views/Teacher/Curriculum/HomeroomTeacherProgress/DetailHomeroom'
  )
const GraduationLetterHomeroomTeacher = () =>
  import(
    /* webpackChunkName: "curriculumTeacher" */ '../views/Teacher/Curriculum/GraduationLetter/DetailHomeroom'
  )
const detailAttendanceClassCurriculumTeacher = () =>
  import(
    /* webpackChunkName: "curriculumTeacher" */ '../views/Teacher/Curriculum/DailyAttendance/DetailDailyAttendance'
  )

const cbtTeacher = () =>
  import(/* webpackChunkName: "cbtTeacher" */ '../views/Teacher/Cbt')
const mySuperVisionCbtTeacher = () =>
  import('../views/Teacher/Cbt/MySupervision')
const addCbtTeacher = () =>
  import(/* webpackChunkName: "addCbtTeacher" */ '../views/Teacher/Cbt/Add')
const detailCbtTeacher = () =>
  import(
    /* webpackChunkName: "detailCbtTeacher" */ '../views/Teacher/Cbt/Detail'
  )
const resultCbtTeacher = () =>
  import(
    /* webpackChunkName: "resultCbtTeacher" */ '../views/Teacher/Cbt/Result'
  )

const PersonalDataHomeroomTeacher = () =>
  import(
    /* webpackChunkName: "PersonalDataHomeroomTeacher" */ '../views/Teacher/Homeroom/PersonalData'
  )
// const homeroomTeacher = () =>
//   import(/* webpackChunkName: "homeroomTeacher" */ '../views/Teacher/Homeroom')
const homeroomReportTeacher = () =>
  import(
    /* webpackChunkName: "homeroomReportTeacher" */ '../views/Teacher/Homeroom/Report'
  )
const detailHomeroomTeacher = () =>
  import(
    /* webpackChunkName: "detailHomeroomTeacher" */ '../views/Teacher/Homeroom/Detail'
  )
const pembinaEkskulDashboard = () =>
  import(
    /* webpackChunkName: "ekskulTeacher" */ '../views/Teacher/PembinaEkskulDashboard'
  )
const pembinaEkskulManageStudents = () =>
  import(
    /* webpackChunkName: "ekskulTeacher" */ '../views/Teacher/PembinaEkskulManageStudents'
  )
const counselingTeacher = () =>
  import(
    /* webpackChunkName: " counselingTeacher" */ '../views/Teacher/Counseling'
  )
// TEACHER

// STUDENT
const accountStudent = () =>
  import(/* webpackChunkName: "accountStudent" */ '../views/Student/Account')
const dashboardStudent = () =>
  import(
    /* webpackChunkName: "dashboardStudent" */ '../views/Student/DashboardStudents'
  )

const scheduleStudent = () =>
  import(
    /* webpackChunkName: "scheduleStudent" */ '../views/Student/ScheduleStudent'
  )
const detailScheduleStudent = () =>
  import(
    /* webpackChunkName: "detailScheduleStudent" */ '../views/Student/DetailSchedule'
  )

const classStudent = () =>
  import(/* webpackChunkName: "classStudent" */ '../views/Student/ClassStudent')
const detailSubjectStudent = () =>
  import(
    /* webpackChunkName: "detailSubjectStudent" */ '../views/Student/DetailSubject'
  )

const examCbtStudent = () =>
  import(
    /* webpackChunkName: "examCbtStudent" */ '../views/Student/ExamCBTList'
  )
const startCbtStudent = () =>
  import(
    /* webpackChunkName: "startCbtStudent" */ '../views/Student/ExamCBTList/StartExamCBT'
  )

const reportStudent = () =>
  import(/* webpackChunkName: "startCbtStudent" */ '../views/Student/Report')
// STUDENT

// ADMIN
const accountAdmin = () =>
  import(/* webpackChunkName: "accountAdmin" */ '../views/Admin/Account')
// const dashboardAdmin = () =>
//   import(/* webpackChunkName: "dashboardAdmin" */ '../views/Admin/Dashboard')

const masterAdmin = () =>
  import(/* webpackChunkName: "dashboardAdmin" */ '../views/Admin/Master')
const studentCandidateAdmin = () =>
  import(
    /* webpackChunkName: "studentCandidateAdmin" */ '../views/Admin/StudentCandidate'
  )
const studentCandidateDetailAdmin = () =>
  import(
    /* webpackChunkName: "studentCandidateDetailAdmin" */ '../views/Admin/StudentCandidate/Detail'
  )

const teachersAdmin = () =>
  import(/* webpackChunkName: "teachersAdmin" */ '../views/Admin/Teachers')
const addTeachersAdmin = () =>
  import(
    /* webpackChunkName: "addTeachersAdmin" */ '../views/Admin/Teachers/Add'
  )
const detailTeachersAdmin = () =>
  import(
    /* webpackChunkName: "detailTeachersAdmin" */ '../views/Admin/Teachers/Detail'
  )
const scheduleTeachersAdmin = () =>
  import(
    /* webpackChunkName: "scheduleTeachersAdmin" */ '../views/Admin/Teachers/Schedule'
  )

const studentsAdmin = () =>
  import(/* webpackChunkName: "studentsAdmin" */ '../views/Admin/Students')
const addStudentsAdmin = () =>
  import(
    /* webpackChunkName: "addStudentsAdmin" */ '../views/Admin/Students/Add'
  )
const detailStudentsAdmin = () =>
  import(
    /* webpackChunkName: "detailStudentsAdmin" */ '../views/Admin/Students/Detail'
  )

const classesAdmin = () =>
  import(/* webpackChunkName: "classesAdmin" */ '../views/Admin/Classes')
const detailClassesAdmin = () =>
  import(
    /* webpackChunkName: "detailClassesAdmin" */ '../views/Admin/Classes/Detail'
  )

const Report = () =>
  import(
    /* webpackChunkName: "rewardPunishmentAdmin" */ '../views/Admin/Report'
  )

// const extracurricularAdmin = () =>
//   import(
//     /* webpackChunkName: "extracurricularAdmin" */ '../views/Admin/Extracurricular'
//   )
const extracurricularDetailAdmin = () =>
  import(
    /* webpackChunkName: "extracurricularAdmin" */ '../views/Admin/Extracurricular/Detail'
  )

const blockedAccountAdmin = () =>
  import(
    /* webpackChunkName: "blockedAccountAdmin" */ '../views/Admin/BlockedAccount'
  )
// ADMIN

const SuccessVerify = () =>
  import(
    /* webpackChunkName: "detailClassesAdmin" */ '../views/Onboarding/SuccessVerify'
  )

const SuccessVerifyParent = () =>
  import(
    /* webpackChunkName: "detailClassesAdmin" */ '../views/Onboarding/SuccessVerifyParent'
  )

const redirectPage = () =>
  import(/* webpackChunkName: "detailClassesAdmin" */ '../views/RedirectPage')

// // ADMIN HR
// const DashboardAdminHR = () => import(/* webpackChunkName: "dashboardAdminHR" */'../views/AdminHR/Dashboard')
// const KehadiranAdminHR = () => import(/* webpackChunkName: "kehadiranAdminHR" */'../views/AdminHR/Attendance')
// const MasterDataAdminHR = () => import(/* webpackChunkName: "masterAdminHR" */'../views/AdminHR/MasterData')
// const detailRekapAbsenAdminHR = () => import(/* webpackChunkName: "detailRekapAbsenAdminHR" */'../views/AdminHR/Attendance/RekapitulasiKehadiran/Detail')
// // ADMIN HR

// // ADMIN KEUANGAN
// const DashboardAdminKeuangan = () => import(/* webpackChunkName: "dashboardAdminHR" */'../views/AdminKeuangan/Dashboard')
// const KeuanganAdminKeuangan = () => import(/* webpackChunkName: "keuanganAdminHR" */'../views/AdminKeuangan/Finance')
// const MasterDataAdminKeuangan = () => import(/* webpackChunkName: "masterAdminHR" */'../views/AdminKeuangan/MasterData')
// // ADMIN KEUANGAN

// // PEGAWAI
// const dasborPegawai = () => import(/* webpackChunkName: "dasborPegawai" */'../views/Pegawai/Dasbor')
// const keuanganPegawai = () => import(/* webpackChunkName: "dasborPegawai" */'../views/Pegawai/Keuangan')

// const MyAttendance = () => import(/* webpackChunkName: "myAttendance" */'../views/Attendance/MyAttendance')

const detailSchoolYearAdmin = () =>
  import(
    /* webpackChunkName: "detailSchoolYearAdmin" */ '@/views/Admin/DetailSchoolYear'
  )

const ppdbStudent = () =>
  import(/* webpackChunkName: "startCbtStudent" */ '../views/Student/PPDB')

const ppdb = () => import(/* webpackChunkName: "ppdbStudent" */ '@/views/Ppdb')
const successPpdb = () =>
  import(/* webpackChunkName: "ppdbStudent" */ '@/views/Ppdb/Success')
const failedPpdb = () =>
  import(/* webpackChunkName: "ppdbStudent" */ '@/views/Ppdb/Failed')

// const ppdbSwasta = () =>
//   import(/* webpackChunkName: "ppdbStudentSwasta" */ '@/views/PpdbSwasta')
// const successPpdbSwasta = () =>
//   import(
//     /* webpackChunkName: "ppdbStudentSwasta" */ '@/views/PpdbSwasta/Success'
//   )
// const failedPpdbSwasta = () =>
//   import(
//     /* webpackChunkName: "ppdbStudentSwasta" */ '@/views/PpdbSwasta/Failed'
//   )

const landingPagePpdb = () =>
  import(/* webpackChunkName: "ppdbStudentSwasta" */ '@/views/Ppdb/LandingPage')
const Announcement = () =>
  import(/* webpackChunkName: "ppdbStudentSwasta" */ '@/views/Announcement')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/teacher',
    component: mainLayout,
    redirect: { name: 'Dashboard Teacher' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['guruMapel'],
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard Teacher',
        component: dashboardTeacher,
      },
      {
        path: 'success-verify',
        name: 'Success Verify Teacher',
        component: SuccessVerify,
      },
      {
        path: 'account',
        name: 'Account Teacher',
        component: accountTeacher,
      },
      {
        path: 'schedule',
        name: 'Schedule Teacher',
        component: scheduleTeacher,
      },
      {
        path: 'planning',
        name: 'Planning Teacher',
        component: planningTeacherNew,
      },
      {
        path: 'assignments',
        name: 'Assignments Teacher',
        component: assignmentTeacher,
      },
      {
        path: 'assignments/:id/grading',
        name: 'Grading Assignments Teacher',
        component: gradingAssignmentTeacher,
      },
      {
        path: 'assignments/:id/:idMapel/presences',
        name: 'Presences Assignments Teacher',
        component: presencesAssignmentTeacher,
      },
      {
        path: 'schedule/:id',
        name: 'Detail Schedule Teacher',
        component: detailScheduleTeacher,
      },
      {
        path: '/schedule/:id/edit',
        name: 'Edit Schedule',
        component: editScheduleTeacher,
      },
      {
        path: 'reports',
        name: 'Reports Teacher',
        component: reportsTeacher,
      },
      {
        path: 'reports/:idMapel/:idKelas/detail',
        name: 'Detail Reports Teacher',
        component: detailReportsTeacher,
      },
      {
        path: 'cbt',
        name: 'Cbt Teacher',
        component: cbtTeacher,
      },
      {
        path: 'cbt/my-supervision/:id',
        name: 'Cbt Supervision Teacher',
        component: mySuperVisionCbtTeacher,
      },
      {
        path: 'cbt/add',
        name: 'Add Cbt Teacher',
        component: addCbtTeacher,
      },
      {
        path: 'cbt/detail/:id',
        name: 'Detail Cbt Teacher',
        component: detailCbtTeacher,
      },
      {
        path: 'cbt/result/:id',
        name: 'Result Cbt Teacher',
        component: resultCbtTeacher,
      },
      {
        path: 'announcement',
        name: 'Announcement Teacher',
        component: Announcement,
      },
      {
        path: 'homeroom/personal-data',
        name: 'Personal Data Homeroom Teacher',
        meta: {
          role: ['waliKelas'],
        },
        component: PersonalDataHomeroomTeacher,
      },
      {
        path: 'homeroom/report',
        name: 'Reports Homeroom Teacher',
        meta: {
          role: ['waliKelas', 'kurikulum', 'kepalaSekolah'],
        },
        component: homeroomReportTeacher,
      },
      {
        path: 'homeroom/report/:id',
        name: 'Detail Homeroom Teacher',
        meta: {
          role: ['waliKelas'],
        },
        component: detailHomeroomTeacher,
      },
      {
        path: 'curriculum',
        name: 'Curriculum Teacher',
        meta: {
          role: ['kurikulum'],
        },
        component: curriculumTeacher,
      },
      {
        path: 'curriculum/daily-attendance/:id_kelas',
        name: 'Curriculum Detail Attendance Report',
        meta: {
          role: ['kurikulum', 'kepalaSekolah'],
        },
        component: detailAttendanceClassCurriculumTeacher,
      },
      {
        path: 'curriculum/graduation-letter/:id_kelas',
        name: 'Graduation Letter Homeroom Teacher',
        meta: {
          role: ['kurikulum', 'kepalaSekolah'],
        },
        component: GraduationLetterHomeroomTeacher,
      },
      {
        path: 'upper/homeroom-teacher-progress/:id_kelas',
        name: 'Homeroom Teacher Progress Teacher',
        meta: {
          role: ['kurikulum', 'kepalaSekolah'],
        },
        component: HomeroomTeacherProgress,
      },
      {
        path: 'principal',
        name: 'Principal Teacher',
        meta: {
          role: ['kepalaSekolah'],
        },
        component: principalTeacher,
      },
      {
        path: 'eca-supervisor',
        name: 'ECA Supervisor Teacher',
        meta: {
          role: ['pembinaEkskul'],
        },
        component: pembinaEkskulDashboard,
      },
      {
        path: 'eca-supervisor/manage',
        name: 'ECA Supervisor Teacher Manage Students',
        meta: {
          role: ['pembinaEkskul'],
        },
        component: pembinaEkskulManageStudents,
      },
      {
        path: 'counseling',
        name: 'Counseling Teacher',
        meta: {
          // role: ['guruBK'],
        },
        component: counselingTeacher,
      },
    ],
  },
  {
    path: '/admin',
    component: mainLayout,
    redirect: { name: 'Master Admin' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['admin', 'guruAdmin'],
    },
    children: [
      // {
      //   path: 'dashboard',
      //   name: 'Dashboard Admin',
      //   component: dashboardAdmin,
      // },
      {
        path: 'master',
        name: 'Master Admin',
        component: masterAdmin,
      },
      {
        path: 'student-candidate',
        name: 'Student Candidate Admin',
        component: studentCandidateAdmin,
      },
      {
        path: 'student-candidate/detail',
        name: 'Detail Student Candidate Admin',
        component: studentCandidateDetailAdmin,
      },
      {
        path: 'teacher',
        name: 'Teacher Admin',
        component: teachersAdmin,
      },
      {
        path: 'teacher/add',
        name: 'Add Teacher Admin',
        component: addTeachersAdmin,
      },
      {
        path: 'teacher/detail/:id',
        name: 'Detail Teacher Admin',
        component: detailTeachersAdmin,
      },
      {
        path: 'teacher/schedule/:id',
        name: 'Schedule Teacher Admin',
        component: scheduleTeachersAdmin,
      },
      {
        path: 'student',
        name: 'Student Admin',
        component: studentsAdmin,
      },
      {
        path: 'student/detail/:id',
        name: 'Detail Student Admin',
        component: detailStudentsAdmin,
      },
      {
        path: 'account',
        name: 'Account Admin',
        component: accountAdmin,
      },
      {
        path: 'students/add',
        name: 'Add Student Admin',
        component: addStudentsAdmin,
      },
      {
        path: 'class',
        name: 'Class Admin',
        component: classesAdmin,
      },
      {
        path: 'class/detail/:id',
        name: 'Detail Class Admin',
        component: detailClassesAdmin,
      },
      {
        path: 'report',
        name: 'Report Admin',
        component: Report,
      },
      // {
      //   path: 'extracurricular',
      //   name: 'Extracurricular Admin',
      //   component: extracurricularAdmin,
      // },
      {
        path: 'extracurricular/:id',
        name: 'Extracurricular Detail Admin',
        component: extracurricularDetailAdmin,
      },
      {
        path: 'blocked-account',
        name: 'Blocked Account Admin',
        component: blockedAccountAdmin,
      },
      {
        path: 'academic-year/:id',
        name: 'Detail School Year Admin',
        component: detailSchoolYearAdmin,
      },
      {
        path: 'announcement',
        name: 'Announcement Admin',
        component: Announcement,
      },
    ],
  },
  {
    path: '/student',
    component: mainLayout,
    redirect: { name: 'Dashboard Student' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['murid'],
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard Student',
        component: dashboardStudent,
      },
      {
        path: 'success-verify',
        name: 'Success Verify Student',
        component: SuccessVerify,
      },
      {
        path: 'account',
        name: 'Account Student',
        component: accountStudent,
      },
      {
        path: 'schedule',
        name: 'Schedule Student',
        component: scheduleStudent,
      },
      {
        path: 'class',
        name: 'Class Student',
        component: classStudent,
      },
      {
        path: 'cbt',
        name: 'Cbt Student',
        component: examCbtStudent,
      },
      {
        path: 'schedule/:id',
        name: 'Detail Schedule Student',
        component: detailScheduleStudent,
      },
      {
        path: 'class/:idSubject',
        name: 'Detail Subject Student',
        component: detailSubjectStudent,
      },
      {
        path: 'cbt/:id/exam',
        name: 'Cbt Start Exam Student',
        component: startCbtStudent,
      },
      {
        path: 'report',
        name: 'Report Student',
        component: reportStudent,
      },
      {
        path: 'PPDB-Dashboard',
        name: 'PPDB Student',
        component: ppdbStudent,
      },
      {
        path: 'announcement',
        name: 'Announcement Student',
        component: Announcement,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: login,
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/ppdb',
    name: 'Ppdb',
    component: ppdb,
    meta: {
      authRequired: false,
      ppdbPage: true,
    },
  },
  {
    path: '/ppdb/landing-page',
    name: 'PpdbLandingPage',
    component: landingPagePpdb,
    meta: {
      authRequired: false,
      ppdbPage: true,
    },
  },
  {
    path: '/ppdb/success/:uuid',
    name: 'Success Ppdb',
    component: successPpdb,
    meta: {
      authRequired: false,
      ppdbPage: true,
    },
  },
  {
    path: '/ppdb/failed',
    name: 'Failed Ppdb',
    component: failedPpdb,
    meta: {
      authRequired: false,
      ppdbPage: true,
    },
  },
  // {
  //   path: '/ppdb',
  //   name: 'PpdbSwasta',
  //   component: ppdbSwasta,
  //   meta: {
  //     authRequired: false,
  //     ppdbPage: true,
  //   },
  // },
  // {
  //   path: '/ppdb/success/:uuid',
  //   name: 'Success Ppdb Swasta',
  //   component: successPpdbSwasta,
  //   meta: {
  //     authRequired: false,
  //     ppdbPage: true,
  //   },
  // },
  // {
  //   path: '/ppdb/failed/:uuid',
  //   name: 'Failed Ppdb Swasta',
  //   component: failedPpdbSwasta,
  //   meta: {
  //     authRequired: false,
  //     ppdbPage: true,
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: forgotPassword,
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: redirectPage,
    meta: {
      authRequired: false,
      generalView: true,
    },
  },
  {
    path: '/public/report-preview/:uuid',
    name: 'Public Report Preview',
    component: publicReportPreview,
    meta: {
      authRequired: false,
      generalView: true,
    },
  },
  {
    path: '/public/graduation-letter-validation/:uuid',
    name: 'Public Graduation Letter Validation',
    component: publicGraduationLetterValidation,
    meta: {
      authRequired: false,
      generalView: true,
    },
  },
  {
    path: '/parent/success-verify',
    name: 'Parent Success Verify',
    component: SuccessVerifyParent,
    meta: {
      authRequired: false,
      generalView: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Auth/404'),
    meta: {
      authRequired: false,
    },
  },
  // {
  //   path: '/admin-hr',
  //   component: mainLayout,
  //   redirect: { name: 'Dasbor Admin HR' },
  //   meta: {
  //     authRequired: true,
  //     hidden: true,
  //     role: ['HR'],
  //   },
  //   children: [{
  //     path: 'dasbor',
  //     name: 'Dasbor Admin HR',
  //     component: DashboardAdminHR,
  //   }, {
  //     path: 'kehadiran',
  //     name: 'Kehadiran Admin HR',
  //     component: KehadiranAdminHR,
  //   }, {
  //     path: 'kehadiran/detail/:idPegawai/:idPeriode',
  //     name: 'Detail Rekap Absen',
  //     component: detailRekapAbsenAdminHR,
  //   }, {
  //     path: 'master-data',
  //     name: 'Master Data Admin HR',
  //     component: MasterDataAdminHR,
  //   }],
  // },
  // {
  //   path: '/admin-keuangan',
  //   component: mainLayout,
  //   redirect: { name: 'Dasbor Admin Keuangan' },
  //   meta: {
  //     authRequired: true,
  //     hidden: true,
  //     role: ['keuangan'],
  //   },
  //   children: [{
  //     path: 'dasbor',
  //     name: 'Dasbor Admin Keuangan',
  //     component: DashboardAdminKeuangan,
  //   }, {
  //     path: 'keuangan',
  //     name: 'Keuangan Admin Keuangan',
  //     component: KeuanganAdminKeuangan,
  //   }, {
  //     path: 'master-data',
  //     name: 'Master Data Admin Keuangan',
  //     component: MasterDataAdminKeuangan,
  //   }],
  // },
  // {
  //   path: '/pegawai',
  //   component: mainLayout,
  //   redirect: { name: 'Dasbor Pegawai' },
  //   meta: {
  //     authRequired: true,
  //     hidden: true,
  //     role: ['guruMapel', 'pegawai'],
  //   },
  //   children: [{
  //     path: 'dasbor',
  //     name: 'Dasbor Pegawai',
  //     component: dasborPegawai,
  //   }, {
  //     path: 'keuangan',
  //     name: 'Keuangan Pegawai',
  //     component: keuanganPegawai,
  //   }, {
  //     path: 'my-attendance',
  //     name: 'My Attendance',
  //     component: MyAttendance,
  //   }],
  // },
  {
    path: '*',
    redirect: { name: '404' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const token = to.query?.token
  const localStorageToken = localStorage.getItem('token')
  let isAdmin = false

  if (localStorageToken) {
    const res = verifyToken(localStorageToken)
    console.log(res, 'inihasilverifytoken')
    if (res.username === 'admin') {
      isAdmin = true
    }
  }
  const query = token ? { token } : {}
  const isOnSEB = store.state.isOnSEB
  const institusi = store.state.master.institusi
  if (
    institusi &&
    !institusi.is_announcement &&
    to.name?.split(' ')?.includes('Announcement')
  ) {
    next({ name: '404' })
  }
  // const isOnSEB = false
  if (
    to.name !== 'Cbt Start Exam Student' &&
    to.matched.some((record) => record.meta.authRequired)
  ) {
    // console.log(to)
    localStorage.setItem('isOnExam', false)
  }

  // kondisi 1 : ngecek meta authRequired url yang dituju
  if (to.matched.some((record) => record.meta.authRequired)) {
    // ngecek apakah sudah login atau belum
    if (!localStorage.authorized) {
      next({
        path: '/login',
        query,
      })
    } else {
      if (to.path === '/') {
        // console.log(to, from, 'masukk1')
        if (token) {
          localStorage.clear()
          localStorage.setItem('version', process.env.VUE_APP_VERSION)
          store.dispatch('master/FETCH_INSTITUSI', null, { root: true })
          next({
            path: '/login',
            query,
          })
        } else {
          if (localStorage.role.includes('guruMapel')) {
            // console.log(to, from, 'masukk2')
            next({
              name: 'Dashboard Teacher',
            })
            // } else if (localStorage.role.includes('murid')) {
            //   next({
            //     name: 'Dashboard Student',
            //   })
          } else if (localStorage.role.includes('murid')) {
            if (localStorage.isPpdb) {
              // next({
              //   name: 'PPDB Student',
              // })
            } else {
              next({
                name: 'Dashboard Student',
              })
            }
          } else if (isAdmin) {
            next({
              name: 'Master Admin',
            })
          } else if (localStorage.role.includes('HR')) {
            next({
              name: 'Kehadiran Admin HR',
            })
          } else if (localStorage.role.includes('keuangan')) {
            next({
              name: 'Keuangan Admin Keuangan',
            })
          } else if (localStorage.role.includes('pegawai')) {
            next({
              name: 'Dasbor Pegawai',
            })
          } else {
            next()
          }
          // else if (localStorage.role.includes('HR')) {
          //   next({
          //     name: 'Kehadiran Admin HR',
          //   })
          // } else if (localStorage.role.includes('keuangan')) {
          //   next({
          //     name: 'Keuangan Admin Keuangan',
          //   })
          // } else if (localStorage.role.includes('pegawai')) {
          //   next({
          //     name: 'Dasbor Pegawai',
          //   })
          // } else {
          //   next()
          // }
        }
      } else {
        if (
          to.matched.some(
            (record) =>
              record.meta.role &&
              !record.meta.role.some((role) =>
                JSON.parse(localStorage.role).includes(role),
              ),
          )
        ) {
          next({
            name: '404',
          })
        } else {
          if (
            to.name === 'Cbt Start Exam Student' &&
            !isOnSEB &&
            store.getters.cbtType !== 'PH' &&
            store.getters.cbtType !== 'PPDB'
          ) {
            next({
              // name: '404',
            })
          } else {
            if (to.matched.some((record) => record.path.startsWith('/admin'))) {
              if (isAdmin) {
                next() // Jika isAdmin === true, izinkan akses
              } else {
                next({
                  name: '404', // Jika bukan admin, arahkan ke halaman 404
                })
              }
            } else {
              next() // Jika bukan rute /admin, lanjutkan navigasi
            }
          }
        }
      }
    }
  } else {
    if (to.meta.generalView) {
      next()
    } else {
      if (
        to.name !== '404' &&
        !to.meta.authRequired &&
        localStorage.authorized
      ) {
        if (to.meta.ppdbPage) {
          // console.log('masuk ppdb')
          next()
        } else {
          if (localStorage.role.includes('guruMapel')) {
            // console.log(to, from, 'masukk5')
            next({
              name: 'Dashboard Teacher',
            })
          } else if (localStorage.role.includes('murid')) {
            next({
              name: 'Dashboard Student',
            })
          } else if (isAdmin) {
            // console.log(to)
            next({
              name: 'Master Admin',
            })
          } else if (localStorage.role.includes('HR')) {
            next({
              name: 'Kehadiran Admin HR',
            })
          } else if (localStorage.role.includes('keuangan')) {
            next({
              name: 'Keuangan Admin Keuangan',
            })
          } else if (localStorage.role.includes('pegawai')) {
            next({
              name: 'Dasbor Pegawai',
            })
          } else {
            next()
          }
          // else if (localStorage.role.includes('HR')) {
          //   next({
          //     name: 'Kehadiran Admin HR',
          //   })
          // } else if (localStorage.role.includes('keuangan')) {
          //   next({
          //     name: 'Keuangan Admin Keuangan',
          //   })
          // } else if (localStorage.role.includes('pegawai')) {
          //   next({
          //     name: 'Dasbor Pegawai',
          //   })
          // } else {
          //   next()
          // }
        }
      } else if (to.path === '/') {
        // console.log(to, from, 'masukk6')
        next({
          path: '/login',
          query,
        })
      } else {
        // console.log(to, from, 'masukk7')
        next()
      }
    }
  }
})

export default router
